import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PropsWithChildren } from "react";
import { Environment } from "../enums/Environment";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

export function DPQueryClientProvider(props: Readonly<PropsWithChildren>) {
  const getReactQueryDevTools = () => {
    return process.env.NEXT_PUBLIC_ENVIRONMENT === Environment.LOCAL ? (
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
    ) : null;
  };

  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
      {getReactQueryDevTools()}
    </QueryClientProvider>
  );
}
