import { useQuery } from "react-query";
import axios from "axios";

const fetchUserMetadata = async (userId: string) => {
  const response = await axios.get(`/api/fetch-user-metadata?userId=${userId}`);
  return response.data;
};

export const useFetchUserMetadata = (userId: string) => {
  return useQuery(["userMetadata", userId], () => fetchUserMetadata(userId), {
    enabled: !!userId,
    refetchOnWindowFocus: false,
  });
};
