import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";

import { useWindowSize } from "app/hooks/useWindowSize";
import { usePathname } from "next/navigation";
import { pageViewTrigger } from "app/utils/GTMUtils";

export interface AppRootContextProps {
  isMobileView: boolean;
  isFetchingPreferences: boolean;
  setIsFetchingPreferences: (isFetchingPreferences: boolean) => void;
  isDarkMode: boolean;
  setIsDarkMode: (isDarkMode: boolean) => void;
  isFormOverlayOpen: boolean;
  setIsFormOverlayOpen: (isFormOverlayOpen: boolean) => void;
  isFontLoaded: boolean;
  setIsFontLoaded: (isFontLoaded: boolean) => void;
}

const AppRootContext = createContext<AppRootContextProps>(undefined!);

export function AppRootProvider(props: Readonly<PropsWithChildren>) {
  const [isMobileView, setIsMobileView] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isFormOverlayOpen, setIsFormOverlayOpen] = useState(false);

  const [isFetchingPreferences, setIsFetchingPreferences] = useState(true);
  const [isFontLoaded, setIsFontLoaded] = useState(false);

  const windowSize = useWindowSize();
  const pathName = usePathname();

  useEffect(() => {
    const widthThreshold = 1024;

    if (windowSize.width < widthThreshold && !isMobileView) setIsMobileView(true);
    else if (windowSize.width >= widthThreshold && isMobileView) setIsMobileView(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  useEffect(() => {
    if (window) {
      if (pathName === "/") {
        setIsDarkMode(false);
        return;
      }

      if (window.matchMedia("(prefers-color-scheme: dark)").matches) setIsDarkMode(true);

      const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

      const darkModeListener = (e: MediaQueryListEvent) => {
        setIsDarkMode(e.matches);
      };

      darkModeMediaQuery.addEventListener("change", darkModeListener);
      pageViewTrigger();
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (document.fonts.check("1em Gotham")) {
        document.documentElement.style.fontFamily = "Gotham";
        setIsFontLoaded(true);
      }
    }
  }, []);

  return (
    <AppRootContext.Provider
      value={{
        isFontLoaded,
        setIsFontLoaded,
        isMobileView,
        isFetchingPreferences,
        setIsFetchingPreferences,
        isDarkMode,
        setIsDarkMode,
        isFormOverlayOpen,
        setIsFormOverlayOpen,
      }}
    >
      {props.children}
    </AppRootContext.Provider>
  );
}

export function useAppRootContext(): AppRootContextProps {
  const context = useContext(AppRootContext);

  if (typeof context === "undefined") {
    throw new Error("useAppRootContext should be used within the AppRootContext provider!");
  }

  return context;
}
