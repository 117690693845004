"use client";

import { UserProvider } from "@auth0/nextjs-auth0/client";
import React, { ReactNode } from "react";
import { ToastContainer } from "react-toastify";
import { Auth0Provider } from "./ctx-auth0/Auth0Context";
import { DPQueryClientProvider } from "./providers/DPQueryClientProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { AppRootProvider } from "./contexts/RootContext";

export function Providers({ children }: Readonly<{ children: ReactNode }>) {
  const client = new QueryClient();
  return (
    <UserProvider>
      <ToastContainer />
      <Auth0Provider>
        <QueryClientProvider client={client}>
          <DPQueryClientProvider>
            <AppRootProvider>{children}</AppRootProvider>
          </DPQueryClientProvider>
        </QueryClientProvider>
      </Auth0Provider>
    </UserProvider>
  );
}
