export enum Environment {
  LOCAL = "local",
  QA = "qa",
  STAGING = "stg",
  PRODUCTION = "prd",
}

// Limits Google Tag Manger to be loaded only in specific envs
export function RenderGTM(env: string): boolean {
  return env == Environment.PRODUCTION || env === Environment.QA;
}
