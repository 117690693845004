import { UserProfile } from "@auth0/nextjs-auth0/client";
import { useFetchUserMetadata } from "app/hooks/useFetchUserMetadata";
import { useFetchUserRole } from "app/hooks/useFetchUserRole";
import { Avatar } from "flowbite-react";
import Link from "next/link";
import { useState, useEffect, useRef } from "react";

export interface AuthenticationButtonsProps {
  user: UserProfile | undefined;
}

export const UserProfileIcon = ({ user }: AuthenticationButtonsProps) => {
  const [avatarMenuOpen, setAvatarMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const stateFetcher = useFetchUserMetadata(user?.sub ?? "");
  const roleFetcher = useFetchUserRole(user?.sub ?? "");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setAvatarMenuOpen(false);
      }
    };

    if (avatarMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [avatarMenuOpen]);

  function renderAccountMenu() {
    return (
      <div
        ref={menuRef}
        className="absolute right-0 top-12 z-50 w-64 rounded-md bg-white p-4 shadow-md ring-1 ring-gray-200 dark:bg-gray-800 dark:ring-gray-700"
        onClick={() => setAvatarMenuOpen(false)}
      >
        <div className="text-center text-sm text-gray-700 dark:text-white">
          <p className="truncate text-base font-medium dark:text-white">{user?.name}</p>
          <p className="truncate text-xs text-grey-dark dark:text-white">{user?.email}</p>
          <p className="truncate text-xs text-grey-dark dark:text-white">
            {roleFetcher?.data?.roles?.data?.[0]?.name ?? "Role: --"} -{" "}
            {stateFetcher?.data?.metadata?.state ?? "State: --"}
          </p>
        </div>

        <div className="my-3 border-t border-gray-200 dark:border-gray-700"></div>

        <div className="flex flex-col gap-2">
          <Link
            prefetch={false}
            href="/settings"
            className="w-full rounded bg-gray-100 px-3 py-2 text-center text-sm text-gray-700 hover:bg-gray-200 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
          >
            Account Settings
          </Link>

          <Link
            prefetch={false}
            href="/api/auth/logout"
            className="w-full rounded bg-red-50 px-3 py-2 text-center text-sm text-red-600 hover:bg-red-100 dark:bg-red-600 dark:text-white dark:hover:bg-red-500"
          >
            Sign Out
          </Link>
        </div>
      </div>
    );
  }

  const handleAvatarClick = () => {
    if (avatarMenuOpen) {
      setAvatarMenuOpen(false);
    }

    setAvatarMenuOpen(true);
  };

  return (
    <div className="relative h-full w-full">
      <button className="absolute right-0 top-0 z-50 cursor-pointer" onClick={handleAvatarClick}>
        <Avatar
          className="z-30"
          img={user?.picture ?? "https://www.gravatar.com/avatar/"}
          alt={user?.name ?? ""}
          size="md"
          rounded
        />
      </button>
      {avatarMenuOpen && renderAccountMenu()}
    </div>
  );
};
